[data-aos="fade-up"] {
	opacity: 0;
	transform: translateY(50px);
	transition: transform 1s $ease, opacity 1s $ease;
	will-change: transform;

	&.appeared {
		opacity: 1;
		transform: translateY(0);
	}
}

[data-aos="opacity"] {
	opacity: 0;
	transition: opacity 1s ease-out .3s;
	will-change: opacity;

	&.appeared {
		opacity: 1;
	}
}

[data-aos="fade-up-stagger"] {
	[data-stagger] {
		transform: translateY(50px);
		opacity: 0;
		transition: transform 1s $ease, opacity 1s $ease;
		will-change: transform;
		@include stagger-transitions(75, 1, 20);
	}

	&.appeared {
		[data-stagger] {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
