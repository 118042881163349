.btn {

	position: relative;

	display: inline-flex;
	align-items: center;
	justify-content: space-between;

	transition: background-position 0.3s $ease, border-radius 0.3s $ease;
	background: var(--section-btn-linear-gradient);
	background-size: 200% 100%;
	background-position: 0 0;

	border-radius: 10px;
	padding: 1.5rem 5rem 1.5rem 1.5rem;
	margin: 0.5rem 0 0 0;

	font-family: $font-alt;
	font-weight: 700;
	font-size: 18px;
	color: var(--section-btn-color);

	@include media-breakpoint-up(md) {
		margin: 1.5rem 0 0 0;
	}

	.ball{
		position: absolute;
		top: calc( 50% - 7px );
		right: 1.5rem;
		width: 14px;
		height: 14px;
		border: 1px solid var(--section-btn-color);
		border-radius: 50%;
		pointer-events: none;
	}

	&.extended{
		display: flex;
		width: 100%;
		@include media-breakpoint-up(lg) {
			justify-content: center;
		}
	}

	&:hover{
		color: var(--section-btn-color);
		background-position: 100% 0;
	}

}

.arrow-go{
	transition: transform 0.5s $ease;
	will-change: transform;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;
	border: 1px solid rgba($color-1, .4);
	border-radius: 50%;

	&::before{
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		background: transparent url('#{$svg-path}arrow-next.svg') center center no-repeat;
		background-size: contain;
	}

	.cta &{
		border-color: $white;
		&::before {
			background-image: url('#{$svg-path}arrow-next-white.svg');
		}
	}

	.card-hover:hover &,
	&:hover{
		transform: translateX(10px);
	}
}

.cta{

	position: relative;
	transition: transform 0.3s $ease;
	will-change: transform;

	padding: 3rem 2rem;
	border-radius: 10px;

	cursor: pointer;

	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	min-height: 250px;
	@include media-breakpoint-up(md) {
		min-height: 450px;
	}

	font-size: clamp(14px, calc(14px + 0.75vw), 50px);
	font-weight: 700;
	color: $white;

	&::before{
		transition: transform 0.3s $ease, filter 0.3s $ease;
		content: "";
		position: absolute;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $black;
		border-radius: 10px;
		opacity: 0.2;
	}

	&::after{
		transition: transform 0.3s $ease;
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $color-3 url('#{$svg-path}stylet.svg') right center no-repeat;
		background-size: cover;
		border-radius: 10px;
	}

	&:hover{
		transform: translate(5px,-5px);
		&::before{
			transform: translate(-5px,5px);
			filter: blur(5px);
		}
	}

}

.btn-model-3d{

	position: relative;
	height: 100%;
	background-color: $color-3;
	padding: 2rem;

	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;

	font-weight: 700;
	color: $white;

	&:hover{
		.img{
			.svg{
				transform: translate(-50%,-50%) rotateZ(180deg);
			}
		}
	}

	.img{
		position: relative;
		margin: 4rem 0 8rem 0;
		width: 100%;
		aspect-ratio: 1/1;
		border-radius: 100%;
		background-color: $black;

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			overflow: hidden;
			border-radius: 100%;
		}

		.gradient{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.icon{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}

		.svg{
			transition: transform .9s $ease;
			will-change: transform;
			perspective: 1200px;
			backface-visibility: hidden;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 150%;
			height: 150%;
			transform: translate(-50%,-50%) scaleX(-1);
			pointer-events: none;
		}
	}

	.inner{
		font-size: clamp(14px, calc(14px + 0.75vw), 50px);
	}

}

.btn-after-model-3d{

	transition: background-position 0.3s $ease;
	position: relative;
	padding: 8rem 2rem 2rem 2rem;
	border-radius: 10px;
	margin-top: 10px;

	font-size: clamp(14px, calc(14px + 0.75vw), 50px);
	font-weight: 700;
	color: $white;

	background-image: linear-gradient(to left, darken($color-5, 15%), $color-5 50%);
	background-size: 200% 100%;
	background-position: 0 0;

	.svg{
		transition: background-color 0.3s $ease;
		position: absolute;
		right: 2rem;
		bottom: 2rem;
		width: 40px;
		height: 40px;
		border: 1px solid rgba($white, .5);
		border-radius: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:hover{
		background-position: 100% 0;
	}

}