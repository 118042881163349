
@mixin input_mobile() {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}


.form{

	.form-field{
		position: relative;

		&::after{
			transition: transform 0.3s $ease;
			will-change: transform;
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $white;
			transform-origin: bottom center;
			transform: scaleY(0);
		}

		&.inside {
			label {
				cursor: pointer;
				transition: font-size 0.3s $ease, color 0.3s $ease, transform 0.3s $ease;
				position: absolute;
				display: flex;
				align-items: center;
				height: 100%;

				font-family: $font-alt;
			}

			&.active,
			&.filled {
				&::after {
					transform: scaleY(1);
				}

				label {
					transform: translateY(-50%);
					font-size: 0.9em;
					color: rgba($white, .5);
					pointer-events: none;
				}
			}
		}

		&.outside{
			label {
				cursor: pointer;
				display: block;
				font-family: $font-alt;
				margin-bottom: 1rem;
			}
		}

		&.type-checkbox{
			font-size: 12px;

			label{
				cursor: pointer;
				span{
					position: relative;
					display: block;
					padding-left: 15px;

					&::before,
					&::after{
						transition: border-color 0.3s $ease;
						content: "";
						position: absolute;
						top: 2px;
						left: 0;
						width: 10px;
						height: 10px;
						border: 1px solid rgba($white, .5);
					}

					&::after{
						transition: opacity 0.3s $ease;
						border: none;
						background-color: $white;
						top: 4px;
						left: 2px;
						width: 6px;
						height: 6px;
						opacity: 0;
					}
				}
			}

			input{
				position: absolute;
				opacity: 0;

				&:checked ~ span{
					&::before{
						border-color: $white;
					}
					&::after{
						opacity: 1;
					}
				}
			}
		}

	}

	input:not([type="textarea"]):not([type="button"]):not([type="checkbox"]),
	textarea{
		transition: border-color 0.3s $ease;
		@include input_mobile();
		width: 100%;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid rgba($white, .2);
		padding: 1rem 0;

		font-family: $font-base;
		color: $white;

		&:focus {
			outline-width: 0;
		}
	}

	textarea{
		background-color: $white;
		border: none;
		border-radius: 10px;
		min-height: 230px;
		padding: 1rem;

		color: $color-1;
	}

	button{
		border: none;
	}

	.form-legend{
		font-size: 12px;
		font-weight: 500;
		color: rgba($white, .5);
	}

}