.block-iteration{

	.block-iteration-number{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 46px;
		width: 46px;
		border: 1px solid $color-3;
		border-radius: 50%;
		margin-bottom: 2rem;

		font-family: $font-alt;
		font-size: 30px;
		color: $color-3;
	}

	.block-iteration-text{
		position: relative;

		@include media-breakpoint-down(lg) {
			border-top: 1px solid rgba($color-3, .2);
			&::before,
			&::after{
				content: "";
				position: absolute;
				top: -1px;
				width: 2px;
				height: 1px;
				background-color: $color-3;
			}

			&::before{
				left: -3px;
			}

			&::after{
				right: -3px;
			}
		}

		@include media-breakpoint-up(lg) {
			border-left: 1px solid rgba($color-3, .2);
			&::before,
			&::after{
				content: "";
				position: absolute;
				left: -1px;
				width: 1px;
				height: 2px;
				background-color: $color-3;
			}

			&::before{
				top: -3px;
			}

			&::after{
				bottom: -3px;
			}
		}
	}

}