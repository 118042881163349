.header-main {

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	pointer-events: none;

	*{
		cursor: pointer;
		pointer-events: initial;
	}

	.header-actions{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.header-subnav{
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(sm) {
				flex-direction: row;
			}
		}
	}

	.header-mailto{
		transition: background-color 0.5s $ease;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: $color-3;

		&:hover{
			background-color: darken($color-3, 10%);
		}

		@include media-breakpoint-up(sm) {
			height: 80px;
			width: 80px;
			border-right: 1px solid rgba($white, .2);
		}
	}

	.nav-lang{
		width: 40px;
		height: 40px;
		background-color: $color-3;

		list-style-type: none;

		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(sm) {
			height: 80px;
			width: 80px;
		}

		li {
			transition: transform 0.5s $ease, opacity 0.5s $ease;
			pointer-events: none;
			a {
				transition: background-color 0.5s $ease;
				height: 40px;
				width: 40px;
				background-color: $color-3;
				display: flex;
				align-items: center;
				justify-content: center;
				pointer-events: none;

				@include media-breakpoint-up(sm) {
					height: 80px;
					width: 80px;
				}

				&:hover {
					background-color: darken($color-3, 10%);
				}
			}

			&:not(.current){
				a{
					border-top: 1px solid darken($color-3, 10%);
				}
				transform: translateY(-20px);
				opacity: 0;
			}
		}

		&:hover{
			li{
				transform: translateY(0px);
				opacity: 1;
				pointer-events: unset;
				a{
					pointer-events: unset;
				}
			}
		}
	}

	.toggle{
		transition: border-radius 0.5s $ease;
		display: flex;
		align-items: center;

		background-color: #fff;
		border-bottom-left-radius: 5px;
		padding: 0 1rem;
		height: 80px;

		text-transform: uppercase;
		font-family: $font-alt;
		font-weight: 800;
		font-size: 16px;
		color: $color-1;

		@include media-breakpoint-up(sm) {
			padding: 0 2rem;
		}

		@include media-breakpoint-up(md) {
			padding: 0 2.7rem;
		}

		.icon{
			transition: transform 0.8s $ease;
			will-change: transform;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			margin-right: 1rem;
			margin-top: -9px;
			width: 2px;
			height: 2px;
			border-radius: 50%;
			background-color: $color-2;

			&::before,
			&::after{
				transition: transform 0.3s $ease;
				will-change: transform;
				content: "";
				position: absolute;
				width: 2px;
				height: 2px;
				border-radius: 50%;
				background-color: $color-2;
				transform: translateY(4px);
			}
			&::after{
				transform: translateY(8px);
			}
		}

		&:hover{
			border-bottom-left-radius: 14px;
			.icon {
				&::before{
					transform: translateY(4px) translateX(-4px);
				}
			}
		}

		.main-nav-open &{
			.icon{
				&::before{
					transform: translateY(4px) translateX(-4px);
				}
			}
			&:hover{
				.icon{
					&::before{
						transform: translateY(4px) translateX(4px);
					}
				}
			}
		}

	}

	.main-logo{
		margin: 1.5rem 0 0 1.5rem;
	}

}

.main-logo{
	display: block;
	padding: 1.5rem 0;
	margin-bottom: 3rem;
	width: 50%;
	//width: 212px;
	//height: calc( 45px + 3rem);
	background: transparent url('#{$svg-path}/logo.svg') left center no-repeat;
	background-size: contain;

	pointer-events: initial;

	@include media-breakpoint-up(lg) {
		//padding: 3.55rem 0;
		width: 254px;
		//height: calc( 58px + 7.1rem);
	}
}