html {
	font-size: $base-font-size;
}

body {
	font-family: $font-base;
	color: $color-text;
	background-color: $color-bg;

	&.mcube-desktop {
		-ms-scroll-chaining: none;
		overscroll-behavior: none;
	}
}

.page-container {
	position: relative;
	overflow: hidden;
	width: 100%;
}
