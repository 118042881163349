.block-title{

	.separator{

		position: relative;
		margin: 1.5rem 0;
		background-color: rgba($white, .2);
		height: 1px;
		width: calc( 100% - 8px );

		&::before,
		&::after{
			content: "";
			position: absolute;
			top: 0;
			width: 2px;
			height: 1px;
			background-color: #fff;
		}

		&::before{
			left: -4px;
		}

		&::after{
			right: -4px;
		}

	}

}