.rich-text,
.mce-content-body {
	line-height: 1.6;

	p {
		margin-bottom: 1.5rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	a:not(.btn) {
		transition: color 0.3s $ease;
		text-decoration: underline;
		&:hover:not([data-bs-toggle="tooltip"]){
			color: $color-3;
		}
		&[data-bs-toggle="tooltip"]{
			white-space: nowrap;
		}
	}

	ul {
		margin: 0 0 2rem 0;
		padding: 0 0 0 1.7rem;
		li {
			list-style-type: disc;
			&:not(:last-of-type){
				margin-bottom: 0.5rem;
			}
		}
	}

	ol {
		margin: 0 0 2rem 0.9em;
		padding: 0;

		li {
			list-style-type: decimal;
		}
	}

	img {
		max-width: 100%;
		height: auto;
		margin-bottom: 1.5rem;
	}

	blockquote {
		margin: 0 0 2rem 0;
	}

	blockquote p {
		margin: 0 0 1rem 0;
		line-height: 1.2;
		font-size: 1.2rem;
		font-weight: 700;
	}

	p + img,
	p + a,
	p + ul,
	p + ol,
	p + blockquote {
		margin-top: 1.5rem;
	}

	p + .title-level-3{
		margin-top: 2em !important;
	}

	.legend{
		display: flex;
		gap: 0.5em;
		font-size: 0.8em;

		&.text-center {
			justify-content: center;
		}

		&::before{
			content: "";
			display: inline-block;
			width: 17px;
			height: 17px;
			background: transparent url('#{$svg-path}information.svg') 0 0 no-repeat;
			flex-shrink: 0;
		}
	}
}
