
.block{

	position: relative;
	z-index: 1;

}

.img-appear{

	height: 100%;

	img{
		transition: opacity 0.3s ease-in-out;
		position: relative;
		z-index: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0;
	}

	.picture-frame-image{
		position: relative;
		height: 110%;

		&::after{
			transition: transform 0.3s $ease;
			will-change: transform;
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: #eee;
			transform: scaleX(0);
		}

		&.appeared{
			&::after{
				animation: image_appear ease-in-out 0.8s forwards;
			}
			img{
				transition-delay: 0.4s;
				opacity: 1;
			}
		}

	}
}

@keyframes image_appear {
	0%{
		transform-origin: left center;
		transform: scaleX(0);
	}
	50%{
		transform-origin: left center;
		transform: scaleX(1);
	}
	50.0001%{
		transform-origin: right center;
	}
	100%{
		transform-origin: right center;
		transform: scaleX(0);
	}
}