.footer-main {

	display: flex;
	align-items: center;
	justify-content: center;

	.footer-content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 2rem;
	}

	.svg{
		display: inline-block;
		margin: 0 auto;
		svg{
			display: block;
			width: 100%;
			max-width: 254px;
			height: auto;
		}
		&.codman svg{
			max-width: 175px;
		}
	}

	ul{
		list-style-type: none;
		display: flex;
		flex-direction: column;

		a{
			transition: color 0.3s $ease;
			display: block;
			padding: 0.5rem 0;
			&:hover{
				color: $color-3;
				&::before{
					transform: translate(-50%,-50%) scale(1) !important;
					opacity: 1 !important;
				}
			}
			&.current{
				text-decoration: underline;
			}
		}

		&.footer-mentions{
			display: inline-flex;
			a{
				position: relative;
				&::before{
					transition: transform 0.3s $ease, opacity 0.3s $ease;
					content: "";
					position: absolute;
					z-index: 0;
					top: 50%;
					left: 50%;
					width: 200%;
					aspect-ratio: 1/1;
					border-radius: 100%;
					background-color: $color-3;
					transform: translate(-50%,-50%) scale(0);
					opacity: 0;
				}
			}
			svg{
				display: block;
				position: relative;
				z-index: 1;
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			justify-content: space-between;
			gap: 1rem;
		}

	}

	@include media-breakpoint-up(lg) {
		.footer-navigation{
			position: relative;
			border-left: 1px solid rgba($white, .2);

			&::before,
			&::after{
				content: "";
				position: absolute;
				left: -1px;
				width: 1px;
				height: 2px;
				background-color: #fff;
			}

			&::before{
				top: -3px;
			}

			&::after{
				bottom: -3px;
			}
		}
	}

}
