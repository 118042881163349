#IEAlertPopover {
	align-items: center;
	justify-content: center;
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 1100;
	display: none;

	.background{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,.4);
	}

	.box{

		width: 90%;
		max-width: 600px;
		z-index: 1100;
		background-color: white;
		padding: 40px;
	}

	.text {
		line-height: 1.6em;

		a {
			color: $color-text;
		}
	}

	.browsers {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 40px 0;

		li {
			display: block;
			text-align: center;
			height: 100%;

			a {
				display: block;
				height: 100%;
				padding: 5px 10px;
				background: $color-text;
				color: #fff;
				font-size: 14px;

				&:hover {
					background: lighten($color-text, 10%);
				}
			}
		}
	}

	.ie-buttons {
		display: flex;
		justify-content: space-between;
	}

	.ie-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		background-color: $color-1;
		color: white;
		width: 150px;
		text-align: center;
		transition: background-color .3s $ease;
		cursor: pointer;

		&:hover{
			background-color: rgba($color-1, .9);
		}
	}
}


