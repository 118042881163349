.block-text-two-column{

	position: relative;

	.bg{
		position: absolute;
		z-index: -1;
		bottom: calc( var(--spacer) * -1 );
		right: 0;
		width: 40%;
		max-width: 530px;
		overflow: hidden;
		img{
			transform: translate(20%,70%);
			width: 100%;
			height: 100%;
		}
	}

}