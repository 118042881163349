
.home-header{
	position: fixed;
	z-index: 10;
}

.home-container{
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.home-intro{
	font-weight: 300;
	font-size: clamp(18px, calc(14px + 0.35vw), 50px);

	strong{
		font-weight: 700;
	}
}

.home-main{

	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	flex-grow: 2;


	.home-main-header{
		flex-grow: 2;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.home-main-footer{
		flex-basis: 0;
	}

	@include media-breakpoint-up(md) {
		justify-content: space-between;
		.home-main-header{
			width: 80%;
			flex-grow: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		.home-main-header{
			width: 50%;
		}
	}
}

.home-footer{
	@extend .py-2;
	@extend .ps-2;
	@extend .ps-4;
	@extend .pe-4;

	width: 100%;

	@include media-breakpoint-up(md) {

		width: auto;
		min-width: 65%;

		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		gap: 4rem;

		.row{
			width: auto;
			border: 1px solid red;
		}
	}
}

.home-circle{
	position: absolute;
	z-index: 0;
	top: 50%;
	left: 50%;
	aspect-ratio: 1/1;
	border-radius: 50%;
	border: 1px solid rgba($white, .2);
	transform: translate(-50%,-50%);
	pointer-events: none;

	&::before{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 8%;
		aspect-ratio: 1/1;
		border-radius: 50%;
		border: 1px solid rgba($white, .2);
		transform: translate(-50%,-50%);
	}

	@include media-breakpoint-down(md) {
		width: 105%;
		height: auto;
		&::before{
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		@media (orientation: landscape) {
			width: 105%;
		}
		@media (orientation: portrait) {
			height: 105%;
		}
	}
}

.home-separator{
	position: absolute;
	z-index: 0;
	top: 1rem;
	left: 50%;
	width: 1px;
	height: 100vh;
	background-color: rgba($white, .2);
	pointer-events: none;

	@include media-breakpoint-down(md) {
		display: none;
	}

	.svg{
		position: absolute;
		top: calc( 50% - 1rem );
		left: 50%;
		width: 7px;
		height: 8px;
		transform: translate(-50%, -50%);
		svg{
			display: block;
		}
	}
	
	&::before{
		content: "";
		position: absolute;
		top: -5px;
		left: 0;
		width: 1px;
		height: 3px;
		background-color: #fff;
		transform: translate(0, -50%);
	}
}