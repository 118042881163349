.block-text-image{

	.img{
		width: 100%;
		overflow: hidden;

		img{
			transition: opacity 0.3s ease-in-out;
			position: relative;
			z-index: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0;
		}

		@include media-breakpoint-up(lg) {

			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: calc( 40% + 100vw - 100% );

		}

		@media (min-width: 2000px) {
			left: calc( -0.5 * ( 100vw - 100% ) );
		}

	}

}