[data-text-appear] {
	visibility: hidden;

	.split-outer {
		overflow: hidden;
		will-change: transform;
	}

	.split-inner {
		display: inline-block;
		will-change: transform;
	}
}
