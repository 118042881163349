.block-list{

	.item-list{

		background-color: $color-3;
		padding: 3rem;
		border-radius: 30px;

		color: $white;

		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;
		aspect-ratio: 1/1;
		width: 100%;

	}

	.item-list-number{
		display: inline-block;
		font-family: $font-alt;
		font-weight: 700;
		font-size: clamp(100px, calc(14px + 7.4vw), 250px);
		line-height: 110%;
		text-align: center;

		span{
			display: inline-block;
			font-size: 0.3em;
			line-height: 0.5em;
		}
	}

}