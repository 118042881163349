.block-movie{

	.movie-container{

		position: relative;
		width: 100%;
		aspect-ratio: 1440/750;
		overflow: hidden;
		background-color: $black;

		.movie-play{
			transition: opacity 0.3s $ease;
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			cursor: pointer;

			.svg{
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				svg{
					position: absolute;
					display: block;
					top: 50%;
					left: 50%;
					width: auto;
					max-height: 80%;
					transform: translate(-85%, -50%);
				}
			}
		}

		.movie-legend{
			transition: opacity 0.3s $ease;
			position: absolute;
			z-index: 1;
			bottom: 2rem;
			left: 2rem;

			border-left: 1px solid rgba($white, .2);
			padding-left: 1rem;

			font-family: $font-alt;
			font-weight: 700;
			font-size: clamp(18px, calc(14px + 0.8vw), 50px);
			line-height: 110%;

			&::before,
			&::after{
				content: "";
				position: absolute;
				left: -1px;
				width: 1px;
				height: 2px;
				background-color: $white;
			}

			&::before{
				top: 0;
			}

			&::after{
				bottom: 0;
			}
		}

		video{
			transition: opacity 0.3s $ease;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.5;
			&.full{
				display: none;
				&:not([poster=""]){
					display: block;
				}
			}
		}

	}

	&.block-movie-wrap{
		video.full{
			display: block;
		}
	}

	&.is-playing{
		.movie-container {
			.movie-play,
			.movie-legend{
				opacity: 0;
				pointer-events: none;
			}
			video{
				opacity: 1;
				&:not(.full){
					opacity: 0;
				}
				&.full{
					opacity: 1;
					display: block;
				}
			}
		}
	}

}