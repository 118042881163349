.block-text-three-column{

	.rich-text{

		font-size: clamp(14px, calc(14px + 0.8vw), 50px);

		&[data-aos]::before{
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			background: transparent url('#{$svg-path}plus-blue.svg') 0 0 no-repeat;
			margin-bottom: 1.5rem;
			opacity: 0.5;
		}

	}

}