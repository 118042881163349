
.main-navigation{

	transition: transform 0.5s $ease;
	will-change: transform;
	position: fixed;
	z-index: 9;
	top: 0;
	right: 0;
	width: 90%;
	height: 100vh;
	background-color: $white;

	display: flex;
	flex-direction: column;
	justify-content: center;

	transform-origin: right center;
	transform: translateX(100%);

	.main-nav-open &{
		transform: translateX(0%);
	}

	.svg{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 8px;
		height: 8px;
		svg{
			display: block;
			width: 8px;
			height: 8px;
			path {
				fill: $color-3;
			}
		}
	}

	ul{
		list-style-type: none;
		width: 65%;
		&.main{
			border-bottom: 1px solid rgba($color-1, .2);
			padding-bottom: 0.5rem;
			margin-bottom: 0.5rem;
			li:first-of-type a{
				text-transform: uppercase;
				font-weight: 700;
				font-size: clamp(20px, calc(14px + 1vw), 50px);
			}
		}
	}

	a:not(.btn){
		transition: color 0.4s $ease;
		display: block;
		padding: 0.8rem 0;
		color: $color-1;
		&.current,
		&:hover{
			color: $color-3;
		}
	}

	@include media-breakpoint-up(lg) {
		width: 40%;
	}
	
}

.overlay-navigation{

	transition: transform 0.5s $ease;
	will-change: transform;
	position: fixed;
	z-index: 8;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba($color-1, .8);
	transform-origin: right center;
	transform: scaleX(0);

	.main-nav-open &{
		transform: scaleX(1);
	}

}