$base-font-size: clamp(14px, calc(11px + 0.25vw), 50px);
$font-base: "libre-franklin", sans-serif;
$font-alt: "karmina-sans", sans-serif;

$image-path: '/images/';
$font-path: '/fonts/';
$svg-path: '/svg/';
$ease: ease;

$color-1: #1B3F92;
$color-2: #005BAC;
$color-3: #E5456A;
$color-4: #0C2B73;
$color-5: #0087FF;

$linear-gradient: linear-gradient(to right, $color-3 0%, $color-3 50%, darken($color-3, 15%));

$color-bg: $color-1;
$color-text: #fff;

$white: #fff;
$black: #000;

$tooltip-font-size: 12px;
$tooltip-color: $color-1;
$tooltip-bg: $white;
$tooltip-opacity: 1;

:root {
	--spacer: calc(18px + 5.8vw);
	--container-fluid-max-width: 2000px;

	--section-background-color: transparent;
	--section-text-color: #{$white};

	--section-btn-linear-gradient: #{$linear-gradient};
	--section-btn-color: #{$white};

}

$grid-breakpoints: (
	xs: 0,
	small: 436px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	hyper: 1800px
);

$container-max-widths: (
	small: 400px,
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px,
	hyper: 1700px
);

$spacer: 2rem; // default : 1rem
$spacers: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	none: 0,
	quarter: calc(var(--spacer) * 0.25),
	third: calc(var(--spacer) * 0.33),
	half: calc(var(--spacer) * 0.5),
	three-quarters: calc(var(--spacer) * .75),
	default: var(--spacer),
	one-half: calc(var(--spacer) * 1.5),
	double: calc(var(--spacer) * 2),
	triple: calc(var(--spacer) * 3)
);

$grid-gutter-width: 2rem;
