.block-text-image-quarter{

	overflow: hidden;
	position: relative;

	.img-appear{
		position: relative;
		margin: 0 calc( -0.5 * var(--spacer) );
	}

	.bg{
		position: absolute;
		z-index: -1;
		bottom: 0;
		right: 0;
		transform: translate(20%,70%);
		img{
			width: 100%;
			height: 100%;
			max-width: 530px;
		}
	}

	&.background-bigger {
		.bg {
			transform: translate(40%,40%);
			img {
				max-width: 800px;
			}
		}
	}

	@include media-breakpoint-up(lg) {

		.img-appear{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: 0;
			left: calc(-0.5 * (100vw - 100%));
			width: calc( 40% + ( 100vw - 100% ) - 0.5 * (100vw - 100%) );
		}

		&.block-text-image-quarter--reverse{
			.img-appear {
				left: auto;
				right: 0;
			}
		}

	}

}