
.try-me-container{
	overflow: hidden;
}

.model-3d-pin{

	position: relative;

	img{
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 75%;
		height: 75%;
		object-fit: contain;
		z-index: 1;
		transform: translate(-50%,-50%);
	}

	.svg{
		max-width: 100%;
		max-height: 60vh;
		svg{
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

}