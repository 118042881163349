body.debug-mode:after {
	display: block;
	padding: 5px 10px;
	content: 'xs';
	position: fixed;
	bottom: 0;
	right: 0;

	background-color: rgba(yellow, 0.8);

	font-size: 11px;
	text-transform: uppercase;
	color: $color-text;
	font-weight: bold;

	@include media-breakpoint-up(sm) {
		content: "sm";
		font-size: 13px;
	}

	@include media-breakpoint-up(md) {
		content: "md";
		font-size: 14px;
	}

	@include media-breakpoint-up(lg) {
		content: 'lg';
		font-size: 15px;
	}

	@include media-breakpoint-up(xl) {
		content: "xl";
		font-size: 16px;
	}

	@include media-breakpoint-up(xxl) {
		content: "xxl";
		font-size: 17px;
	}

	@include media-breakpoint-up(hyper) {
		content: "hyper";
		font-size: 18px;
	}
}
