ul,
ol {
	padding: 0;
	margin: 0;
	list-style: auto;
}

a {
	text-decoration: none;
	color: currentColor;

	&:hover {
		color: currentColor;
	}
}

.title-level-1 {
	font-family: $font-alt;
	font-weight: 700;
	font-size: clamp(36px, calc(14px + 3.9vw), 150px);
	line-height: 110%;
}

.title-level-2 {
	margin: 0 0 0.5em 0;
	font-weight: 700;
	font-size: clamp(14px, calc(14px + 2.1vw), 50px);

	sup{
		display: inline-block;
		transform: translateY(-5px);
		font-size: 0.4em;
	}
}

.title-level-3 {
	margin: 0 0 1em 0;
	font-size: clamp(14px, calc(14px + 1.15vw), 50px);
}

.title-level-4 {
	margin: 0 0 1em 0;
	font-weight: 700;
	font-size: 1.4rem;
}

.subtitle{
	display: block;
	font-family: $font-alt;
	font-weight: 300;
	line-height: 100%;
}

.text-highlight {
	font-family: $font-base;
	font-size: clamp(14px, calc(14px + 1.1vw), 100px);
	font-weight: 100;
	line-height: 130%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.text-color {
	color: $color-1;
}

.text-color-alt {
	color: $color-3;
}
