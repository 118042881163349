@keyframes opacity {
	0% {
		opacity: 0;
	}

	80% {
		opacity: .8;
	}

	100% {
		opacity: 1;
	}
}

@keyframes apparition {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}