.block-text-image-third{

	overflow: hidden;

	.img-appear{
		position: relative;
		margin: 0 calc( -0.5 * var(--spacer) );
	}

	@include media-breakpoint-up(lg) {

		.img-appear{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: 0;
			left: calc(-0.5 * (100vw - 100%));
			width: calc( 25% + ( 100vw - 100% ) - 0.5 * (100vw - 100%) );
		}

		.container-reverse{
			.img-appear{
				left: auto;
				right: 0;
			}
			svg{
				transform: rotate(180deg);
			}
		}

	}

}