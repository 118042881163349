@mixin stagger-transitions( $interval, $min, $max, $initial : 0, $reverse : false ) {
	@for $i from $min through $max {
		@if $reverse {
			&:nth-last-child(#{$i}) {
				transition-delay: calc(#{$initial}ms + #{$interval * $i}ms)
			}
		} @else {
			&:nth-child(#{$i}) {
				transition-delay: calc(#{$initial}ms + #{$interval * $i}ms)
			}
		}
	}
}
