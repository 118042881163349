.block-model-3d{

	.model-container{

		position: relative;
		width: 100%;
		aspect-ratio: 1440/750;
		overflow: hidden;
		background-color: $black;

		cursor: pointer;

		&:hover{
			.model-play{
				&::before{
					transform: translate(-50%,-50%) scale(1.2);
					background-color: rgba($white, 0.5);
				}
			}
		}

		.model-poster{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.model-play{
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			width: 80px;
			height: 80px;
			background-color: $color-3;
			border-radius: 100%;
			transform: translate(-50%,-50%);

			display: flex;
			align-items: center;
			justify-content: center;

			.svg{
				position: relative;
				z-index: 1;
			}

			&::after{
				transition: transform 0.3s $ease, background-color 0.3s $ease;
				content: "";
				position: absolute;
				z-index: 0;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				border-radius: 100%;
				background-color: $color-3;
				transform: translate(-50%,-50%);
			}

			&::before{
				transition: transform 0.3s $ease, background-color 0.3s $ease;
				content: "";
				position: absolute;
				z-index: -1;
				top: 50%;
				left: 50%;
				width: calc( 100% + 25px );
				height: calc( 100% + 25px );
				border: 1px solid rgba($white, .5);
				border-radius: 100%;
				background-color: rgba($white, 0);
				transform: translate(-50%,-50%);
			}
		}

		.model-legend{
			position: absolute;
			z-index: 1;
			bottom: 2rem;
			left: 2rem;

			border-left: 1px solid rgba($white, .2);
			padding-left: 1rem;

			font-family: $font-alt;
			font-weight: 700;
			font-size: clamp(18px, calc(14px + 0.8vw), 50px);
			line-height: 110%;

			&::before,
			&::after{
				content: "";
				position: absolute;
				left: -1px;
				width: 1px;
				height: 2px;
				background-color: $white;
			}

			&::before{
				top: 0;
			}

			&::after{
				bottom: 0;
			}
		}

	}

}