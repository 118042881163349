
.animate-to-scroll{

	position: relative;
	margin: 0 auto;
	width: 1px;
	height: 190px;
	background-color: rgba($white, .2);

	.svg{
		position: absolute;
		top: 0;
		left: 50%;
		width: 7px;
		height: 8px;
		transform: translate(-50%, -12px);
		svg{
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	
	&::after{
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 1px;
		height: 3px;
		background-color: #fff;
		transform: translate(0, 6px);
	}
	
	.line{
		display: block;
		width: 1px;
		height: 100%;
		background-color: #fff;
		transform-origin: top center;
		transform: scaleY(0);
		animation: line_animation 3s linear infinite;
	}

}

@keyframes line_animation {
	0%{
		transform: translateY(0%) scaleY(0);
	}
	33%{
		transform: translateY(0%) scaleY(0.5);
	}
	66%{
		transform: translateY(50%) scaleY(0.5);
	}
	100%{
		transform: translateY(100%) scaleY(0);
	}
}