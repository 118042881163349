.block-list-cta{

	.block-list-cta-btn{

		transition: background-position 0.3s $ease;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;

		background-image: linear-gradient(to right, $color-3 50%, darken($color-3, 15%) 100%);
		background-position: 0 0;
		background-size: 200% 100%;

		border-radius: 10px;
		padding: 2rem;

		cursor: pointer;

		&:hover{
			background-position: 100% 0;
			.svg{
				transform: translateX(10px);
			}
		}

		a{
			font-family: $font-alt;
			font-weight: 700;
			font-size: clamp(25px, calc(14px + 0.8vw), 50px);
			width: 15%;
		}

		.svg{
			transition: transform 0.3s $ease;
			will-change: transform;
			display: flex;
			align-items: center;
			justify-content: center;

			width: 40px;
			height: 40px;
			border: 1px solid rgba($white, .5);
			border-radius: 50%;

			flex-shrink: 0;
		}

		.label{
			padding-right: 2rem;
			flex-grow: 2;
		}

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			align-items: flex-start;
			a{
				width: 100%;
			}
			.svg{
				align-self: flex-end;
			}
		}

	}

}