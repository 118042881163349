
.modal-body{
	position: relative;

	video{
		width: 100%;
		height: auto;
		border-radius: 0.5rem;
		transform: translateZ(0);
	}
}

.modal-video{
	.modal-body {
		padding: 0;
	}
	.modal-content{
		background-color: transparent;
	}
}

.modal-close{

	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	border-top-right-radius: 0.5rem;

	cursor: pointer;

	&::before,
	&::after{
		transition: transform 0.3s $ease;
		will-change: transform;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 1px;
		background: $color-1;
		transform: translate(-50%,-50%) rotate(45deg);
	}

	&::after{
		transform: translate(-50%,-50%) rotate(-45deg);
	}

	&:hover{

		&::before, &::after{
			transform: translate(-50%,-50%) rotate(0);
		}

	}

}