.block-text-image-half{

	overflow: hidden;

	.img-appear{
		width: 100%;
		overflow: hidden;
	}

	.col-text{
		position: relative;
		display: flex;
		align-items: center;

		.bg{
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translate(30%,60%);
			img{
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

}