.block-slider-cta{

	.block-slider-navigation{
		border: 1px solid rgba($color-1, .5);
		border-radius: 10px;
		padding: 12px;

		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}

	.block-slider-btn{
		transition: background-color 0.3s $ease, color 0.3s $ease;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		padding: 1.5rem;
		border-radius: 10px;

		font-family: $font-alt;
		font-size: clamp(14px, calc(14px + 0.8vw), 50px);

		cursor: pointer;

		&:hover:not(.swiper-button-disabled){
			background-color: rgba($color-1, .05);
		}

		&.swiper-button-disabled{
			background-color: $color-3;

			font-weight: 700;
			color: $white;
		}
	}

}