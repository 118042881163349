.break-out-left {
	position: relative;
	left: calc(-1 * (50vw - 50%));
	padding-left: 0.5rem;

	&.align-to-grid {
		padding-left: calc((50vw - 50%));
	}
}

.break-out-right {
	min-width: calc(50vw + 50%);
}
