.block-scrolling-content{

	position: relative;
	overflow: hidden;

	.pin-element{

		.bg{
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: 0;
			width: 50%;
			height: 50%;
			opacity: 0.3;
			transform: translate(-20%,30%) rotate(-135deg);
			img{
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.pin-element{
			position: relative;
			.bg{
				width: 250%;
				height: 250%;
				transform: translate(-50%,50%) rotate(-135deg);
			}
		}
	}

	.scrolling-pane {

		&:not(:last-of-type) {
			@extend .mb-default;
		}

		position: relative;
		font-weight: 300;
		font-size: clamp(20px, calc(14px + 3.5vw), 50px);

		&::before{
			content: "";
			position: absolute;
			top: 0.5em;
			left: 0;
			background: transparent url('#{$svg-path}plus-blue.svg') 0 0 no-repeat;
			background-size: contain;
			width: 8px;
			height: 8px;
			transform: translateX(-100%);
		}

		@include media-breakpoint-up(md) {
			opacity: 0;
			&::before{
				transform: translateX(-200%);
			}
		}

		&.scrolling-pane--legend{
			font-size: clamp(12px, calc(14px + 3.5vw), 16px);
			&::before{
				display: none;
			}
		}

	}

}