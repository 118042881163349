
.follower-gradient-container{
	position: relative;
	overflow: hidden;

	& > *{
		position: relative;
		z-index: 1;
	}
}

.follower-gradient{
	transition: opacity 0.3s $ease;
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	width: 100%;
	aspect-ratio: 1/1;
	background: radial-gradient(#1C5BEE 0%, $color-1 60%);
	transform: translate(-50%, -50%);
	opacity: 0.5;

	&.disabled{
		opacity: 0;
	}

	.touchevents &{
		display: none;
	}
}