.container-fluid-px,
.container-fluid-px-mw {
	@extend .container-fluid;
	@extend .px-2;
	@extend .px-sm-default;
}

.container-fluid-px-mw {
	max-width: calc(var(--container-fluid-max-width) + calc(var(--spacer) * 2));
}
