
.pin-info{

	.home &{
		display: none;
	}

	position: fixed;
	z-index: 7;
	bottom: 0;
	right: 0;

	background-color: $color-3;
	border-radius: 10px 0 0 0;

	display: flex;
	align-items: center;

	& > .svg{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 70px;
		width: 70px;
		svg{
			path{ fill: $white; }
		}
	}

	&:hover{
		span:first-of-type{
			transform: translateX(0%);
		}
	}

	& > span:first-of-type{
		transition: transform 0.3s $ease;
		will-change: transform;
		font-family: $font-alt;
		font-weight: 700;
		font-size: 14px;

		display: flex;
		align-items: center;
		gap: 15px;

		position: absolute;
		top: 0;
		right: 0;
		transform-origin: right center;

		white-space: nowrap;
		height: 70px;
		padding: 1rem 1.5rem;
		background-color: $color-3;
		border-radius: 10px 0 0 0;

		transform: translateX(100%);

		.svg{
			display: flex;
			align-items: center;
			justify-content: center;

			width: 40px;
			height: 40px;
			border: 1px solid rgba($white, .2);
			border-radius: 50%;
			svg{
				path{ stroke: $white; }
			}
		}
	}

}