.block-banner{

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		width: 1px;
		height: 20%;
		background-color: rgba($white, .2);
		transform: translateX(-50%);
	}

	&::after{
		content: "+";
		position: absolute;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
	}

	&.block-banner-movie{
		&::before{
			display: none;
		}
	}

	*{
		position: relative;
		z-index: 1;
	}

	.video{
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&::before{
			content: "";
			position: absolute;
			z-index: 10;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 40%;
			background-image: linear-gradient(to top, $color-bg, rgba($white, 0));
		}

		video{
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.title-svg{
		display: block;
		width: 0.8em;
		margin: 0 auto 1rem auto;
		svg{
			display: block;
			width: 100%;
			height: 100%;
		}
	}

}